import { deleteApiWrapper, getApiWrapper, postApiWrapper } from './token-wrapper-function.js';

export const createPost = async (data) => {
  const result = await postApiWrapper(`/api/posts`, data);
  return result;
};
export async function deletePost(postId) {
  const result = await deleteApiWrapper(`/api/posts/${postId}`);
  return result;
}
export const editPost = async (postId, data) => {
  const result = await postApiWrapper(`/api/posts/${postId}`, data);
  return result;
};
export const getAllPosts = async () => {
  const result = await getApiWrapper(`/api/posts`);
  return result;
};
export const getPost = async (postId) => {
  const result = await getApiWrapper(`/api/posts/${postId}`);
  return result;
};

export const getNextPosts = async (lastPostId) => {
  const result = await getApiWrapper(`/api/posts?last_post_id=${lastPostId}`);
  return result;
};

export const postLikeByUser = async (postId, data) => {
  const result = await postApiWrapper(`/api/posts/${postId}/like`, data);
  return result;
};

export const postCommentByUser = async (postId, data) => {
  const result = await postApiWrapper(`/api/posts/${postId}/comment`, data);
  return result;
};

export const postShareByUser = async (postId, data) => {
  const result = await postApiWrapper(`/api/posts/${postId}/share`, data);
  return result;
};

export const getFeedPreSignedUrl = async (data) => {
  const result = await postApiWrapper('/api/photo_upload/file_upload_url', data);
  return result;
};
